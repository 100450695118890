import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { NgModel } from '@angular/forms';
import firebase from 'firebase/app'
import { RefDetails } from './ref-details.model';

@Component({
  selector: 'app-global-details',
  templateUrl: './global-details.component.html',
  styleUrls: ['./global-details.component.scss']
})
export class GlobalDetailsComponent implements OnInit {

  @Input() uid: string;

  public isLoading: boolean = true;

  public detailRef: DocumentReference<RefDetails>;
  
  public hasChanged: boolean;

  public initial: string|null;

  public body: string;

  constructor(
    private store: AngularFirestore
  ) { }

  ngOnInit(): void {
    if (this.uid) {
      this.detailRef = this.store.doc<RefDetails>('ref-details/'+this.uid).ref;
      this.detailRef.onSnapshot({
        next: (doc)=>{
          this.isLoading = false;
          this.initial = doc.data()?.body ?? null;
          this.body = doc.data()?.body ?? "";
          this.checkChanged();
        },
        error: ()=>{
          this.isLoading = false;
          this.initial = null;
        }
      });
    };
  }

  updateDetails(): void {
    var body = this.body;

    if (!this.uid) return;
    
    const req: RefDetails = {
      body: body,
    };
    this.detailRef.set(req).finally(()=>{
      this.checkChanged();
    });
  }

  checkChanged(): void {
    this.hasChanged = !(this.body === (this.initial ?? ""));
  }

}
