import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatDialog } from '@angular/material/dialog';
import { DeleteImageDialogComponent } from '../delete-image-dialog/delete-image-dialog.component';
import { ImageDialogComponent } from '../image-dialog/image-dialog.component';
import { ImageRefs } from '../models/image-refs.model';

@Component({
  selector: 'app-image-thumb',
  templateUrl: './image-thumb.component.html',
  styleUrls: ['./image-thumb.component.scss']
})
export class ImageThumbComponent implements OnInit {

  @Input() imageRef: ImageRefs;
  @Output() onDelete = new EventEmitter<boolean>();

  public thumbURL: string|ArrayBuffer|null;

  public isLoading: boolean = false;

  constructor(
    public storage: AngularFireStorage,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.getThumb(0);
  }

  getThumb(x: number) {
    if (x > 15) {
      this.onDelete.emit(true);
    }
    this.storage.ref(this.imageRef.doc.data().thumbPath).getDownloadURL().subscribe({
      next: url => { 
        this.thumbURL = url;
        this.isLoading = false;
      },
      error: error => { setTimeout(()=>{
        this.getThumb(x+1);
      }, 1000) }
    })
  }

  openFull() {
    this.dialog.open(ImageDialogComponent, {
      data: {
        ref: this.imageRef
      }
    });
  }

  deleteFile() {
    const dialogRef = this.dialog.open(DeleteImageDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.imageRef.file.delete().then(x=>{
          this.imageRef.doc.ref.delete().then(x=>{
            this.onDelete.emit(true);
          })
        })
      }
    });
  }

}
