<mat-card>
    <mat-card-actions>
        <button mat-stroked-button routerLink="/requests"><mat-icon>pages</mat-icon> Requests</button>
    </mat-card-actions>
    <mat-spinner *ngIf="isLoading"></mat-spinner>
    <ng-container *ngIf="!isLoading">
        <mat-card class="usercard" *ngFor="let user of userList" [routerLink]="['/user', user.uid]">
            <mat-card-title>{{ user.username }}</mat-card-title>
            <mat-card-subtitle>{{ user.handle !== undefined ? user.handle : user.uid }}</mat-card-subtitle>
        </mat-card>
    </ng-container>
</mat-card>