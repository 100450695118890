<mat-card-header>
    <mat-card-title>
        Delete image?
    </mat-card-title>
</mat-card-header>
<mat-card-content>
    Are you sure you'd like to delete this image?
</mat-card-content>
<mat-card-actions>
    <button mat-button (click)="onNoClick()">
        <mat-icon>cancel</mat-icon> Cancel
    </button>
    <button mat-button color="warn" (click)="onYesClick()">
        <mat-icon>delete</mat-icon> Delete
    </button>
</mat-card-actions>