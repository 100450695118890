import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from '../auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestUser } from '../login-page/request-user.model';

@Component({
  selector: 'app-root',
  templateUrl: './user-page.component.html',
  styleUrls: ['./user-page.component.scss']
})
export class UserPageComponent {

  public isLoadingUser: boolean = true;
  public get isLoaded() : boolean {
    return (this.currentUser !== null && !this.isLoadingUser) ;
  }
  public get displayName() : string {
    return this.currentUser?.username ?? "" ;
  }
  public get avatar() : string {
    return this.currentUser?.photo ?? "" ;
  }
  public get uid(): string|null {
    return this.currentUser?.uid ?? null ;
  }
  public get handle(): string|null {
    return "@"+this.currentUser?.handle ?? null ;
  }

  public currentUser: RequestUser | undefined;

  constructor (
    public store: AngularFirestore,
    public afAuth: AngularFireAuth,
    public authService: AuthService,
    public route: ActivatedRoute,
    public router: Router,
    public auth: AuthService,
  ) {
    this.isLoadingUser = true;
    var uid = this.route.snapshot.paramMap.get('uid');
    if (this.authService.newUser?.uid === uid) {
      this.currentUser = this.authService.newUser;
      this.isLoadingUser = false;
    } else {
      store.doc<RequestUser>('users/'+uid).get().subscribe(user=>{
        if (!user.data()) {
          this.router.navigate([""]);
        } else {
          console.log("here");
          this.afAuth.currentUser.then(x=>{
            if (this.uid === x?.uid && !user.data()?.handle) {
              this.authService.logout();
            }
          })
          this.currentUser = user.data();
          this.isLoadingUser = false;
        }
      });
    }
  }

  goToTwitter() {
    window.location.href = 'https://twitter.com/'+this.currentUser?.handle;
  }
  
}
