import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
  selector: 'app-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.scss']
})
export class ImageDialogComponent {

  public url = "";

  public isLoading: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<ImageDialogComponent>,
    public storage: AngularFireStorage,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.storage.ref(this.data.ref.doc.data().path).getDownloadURL().subscribe((url: string)=>{
      this.url = url;
      this.isLoading = false;
    })
  }

  

  onNoClick(): void {
    this.dialogRef.close();
  }
}
