import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { RequestUser } from '../login-page/request-user.model';

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.scss']
})
export class UserViewComponent implements OnInit {

  public userList: RequestUser[] = [];

  public isLoading: boolean = true;

  constructor(
    private storage: AngularFirestore
  ) { }

  ngOnInit(): void {
    this.storage.collection<RequestUser>("users").ref.onSnapshot(users=>{
      var newList: RequestUser[] = [];
      users.forEach(user => {
        newList.push(user.data());
      });
      this.userList = newList;
      this.isLoading = false;
    });
  }

}
