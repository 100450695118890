import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StyleService {

  private _style = new BehaviorSubject<boolean>(true);
  public get dark() : Observable<boolean> {
    return this._style.asObservable();
  }
  

  constructor() { }
}
