<mat-toolbar [class.darkMode]="style.dark | async">
  <a href="Https://ralek.art/archive">
    <button mat-stroked-button>
      <mat-icon>undo</mat-icon>
      <span class="hide-mobile">Back to Ralek.art</span>
    </button>
  </a>
  <span class="center">
    Ralek art requests
  </span>
</mat-toolbar>

<div class="center-column" [class.darkMode]="style.dark | async"> 
  <router-outlet></router-outlet>
</div>