import { Component } from '@angular/core';
import { StyleService } from './style.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor (
    public style: StyleService,
  ) {}
}
