<mat-card>
    <mat-card-actions>
        <button mat-stroked-button routerLink="/users"><mat-icon>person</mat-icon> Users</button>
    </mat-card-actions>

    <mat-card-title>
        <p>
            Unread requests
        </p>
    </mat-card-title>
    <mat-spinner *ngIf="unreadIsLoading"></mat-spinner>
    <ng-container *ngIf="!unreadIsLoading">
        <p *ngIf="unreadRequestList.length === 0">No unread requests!</p>
    </ng-container>
    <ng-container *ngIf="!unreadIsLoading">
        <app-request-card class="request-card" [showUserBtn]="true" [canEdit]="false" *ngFor="let request of unreadRequestList; trackBy:identifyRequest" [request]="request"></app-request-card>
    </ng-container>

    <mat-card-title>
        <p>
            Favorites
        </p>
    </mat-card-title>
    <mat-spinner *ngIf="readIsLoading"></mat-spinner>
    <ng-container *ngIf="!readIsLoading">
        <app-request-card class="request-card" [showUserBtn]="true" [canEdit]="false" *ngFor="let request of readRequestList; trackBy:identifyRequest" [request]="request"></app-request-card>
    </ng-container>

</mat-card>