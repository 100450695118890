import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from 'src/environments/environment';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { FormsModule } from '@angular/forms';
import { RequestCardComponent } from './request-card/request-card.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { AngularFireStorageModule  } from '@angular/fire/storage';
import { ImageDialogComponent } from './image-dialog/image-dialog.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ImageThumbComponent } from './image-thumb/image-thumb.component';
import { GlobalDetailsComponent } from './global-details/global-details.component';
import { RequestManagerComponent } from './request-manager/request-manager.component';
import { UserViewComponent } from './user-view/user-view.component'
import { UserPageComponent } from './user-page/user-page.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { RequestsViewerPageComponent } from './requests-viewer-page/requests-viewer-page.component';
import { DeleteImageDialogComponent } from './delete-image-dialog/delete-image-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    RequestCardComponent,
    DeleteDialogComponent,
    DeleteImageDialogComponent,
    ImageDialogComponent,
    ImageThumbComponent,
    GlobalDetailsComponent,
    RequestManagerComponent,
    UserViewComponent,
    UserPageComponent,
    LoginPageComponent,
    RequestsViewerPageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    FormsModule,
    MatDialogModule,
    AngularFireStorageModule,
    MatProgressBarModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
