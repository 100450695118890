// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBVLEuwSxnnCezbggeTzHU9wZz3_CD3I28",
    authDomain: "ralek-requests.firebaseapp.com",
    projectId: "ralek-requests",
    storageBucket: "ralek-requests.appspot.com",
    messagingSenderId: "881827546240",
    appId: "1:881827546240:web:d9f912fbd9dfd6c78cd265"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
