import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { NgForm } from '@angular/forms';
import firebase from 'firebase/app';
import { ArtRequest } from '../models/art-request.model';
import { AuthService } from '../auth.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-request-card',
  templateUrl: './request-card.component.html',
  styleUrls: ['./request-card.component.scss']
})
export class RequestCardComponent implements OnInit {

  @Input() request: firebase.firestore.QueryDocumentSnapshot<ArtRequest>;
  @Input() showUserBtn: boolean = false;
  @Input() canEdit: boolean = true;

  isEditing: boolean;

  requestBody: string;

  constructor(
    public afAuth: AngularFireAuth,
    public authService: AuthService,
    private store: AngularFirestore,
    public dialog: MatDialog,
    public auth: AuthService,
  ) { }

  ngOnInit(): void {
  }


  deleteRequest(): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.collection<ArtRequest>('requests').doc(this.request.id).delete().catch((error) => {
          console.error("Error removing document: ", error);
        });
      }
    })
  }

  updateRequest(form: NgForm): void {
    let data = this.request.data();
    data.body = form.value.body;
    this.request.ref.update(data).catch((error) => {
      console.error("Error removing document: ", error);
    });
  }

  favRequest(status: boolean): void {
    let data = this.request.data();
    data.read = true;
    data.fav = status;
    this.request.ref.set(data);
  }

  readRequest(status: boolean): void {
    let data = this.request.data();
    data.read = status;
    this.request.ref.set(data);
  }

  startEditing(form: NgForm): void {
    this.isEditing = true;
    this.requestBody = this.request.data().body;
    console.log(this.requestBody);
  }

  stopEditing(): void {
    this.isEditing = false;
  }

}
