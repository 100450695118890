<mat-card>
    <ng-container *ngIf="!isLoading">
        <img [src]="thumbURL" alt="" (click)="openFull()">
    </ng-container>
    <ng-container>
        <mat-spinner [diameter]="70" *ngIf="isLoading"></mat-spinner>
    </ng-container>
</mat-card>
<button class="delete" mat-mini-fab color="warn" (click)="deleteFile()">
    <mat-icon>delete</mat-icon>
</button>