import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore, QueryDocumentSnapshot } from '@angular/fire/firestore';
import { ArtRequest } from '../models/art-request.model';
import { AuthService } from '../auth.service';
import firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { NgForm, NgModel } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';
import { RefImage } from '../models/ref-image.model';
import { ImageRefs } from '../models/image-refs.model';
import { RequestUser } from '../login-page/request-user.model';


@Component({
  selector: 'app-request-manager',
  templateUrl: './request-manager.component.html',
  styleUrls: ['./request-manager.component.scss']
})
export class RequestManagerComponent implements OnInit {

  @Input() public user: RequestUser;

  contentTypes = [
    "image/jpeg",
    "image/png",
    "image/tiff",
    "image/webp",
  ]

  // .valueChanges({ idField: 'id'})

  public requestCollection = this.store.collection<ArtRequest>('requests').ref;
  public referenceCollection = this.store.collection<RefImage>('images').ref;
  public requests: firebase.firestore.QueryDocumentSnapshot<ArtRequest>[];
  public images: firebase.firestore.QueryDocumentSnapshot<RefImage>[];
  public isLoggingIn: boolean;

  public imageRefs: ImageRefs[] = [];
  public uploads: number[] = [];

  public get isLoggedIn() : boolean {
    return (this.user !== null) ;
  }

  public bodyForm: string;

  constructor (
    public afAuth: AngularFireAuth,
    public storage: AngularFireStorage,
    public authService: AuthService,
    private store: AngularFirestore,
  ) {

  }

  ngOnInit() {
    if (this.user) {
      const uid = this.user.uid;
      this.requestCollection.where("owner", "==", uid).onSnapshot(x => {
        this.requests = x.docs;
      });
      this.referenceCollection.where("owner", "==", uid).onSnapshot(docRefs => {
        this.storage.ref("images/"+uid).listAll().subscribe(fileRefs=>{
          var imageRefs: ImageRefs[] = [];
          fileRefs.items.forEach(fileRef=>{
            docRefs.forEach(docRef=>{
              if (fileRef.fullPath == docRef.data().path) {
                imageRefs.push({doc: docRef, file: fileRef});
              }
            })
          })
          this.imageRefs = imageRefs;
        });
      })
    }
  }

  onInputChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    const images: File[] = Array.from(target.files as FileList);
    if (!images) return;
    
    images.forEach((file: File) => {

      // get metadata of mime type
      if (this.contentTypes.indexOf(file.type) === -1 ) return;
      const metadata = {
        contentType: file.type,
      }

      // build image
      const n = Date.now()
      const baseName = file.name.substr(0, file.name.lastIndexOf('.'));
      const extension = file.name.split('.').pop();
      const name = n+"-"+encodeURIComponent(baseName+"."+extension);
      const thumbName = n+"-"+encodeURIComponent(baseName+"_200x200."+extension);
      const path = "images/"+this.user.uid+"/"+name;
      const thumbPath = "images/"+this.user.uid+"/thumb/"+thumbName;
      const image = {
        owner: this.user.uid ?? "",
        name: name,
        path: path,
        thumbPath: thumbPath,
      }

      // upload image
      var uploadIndex = this.uploads.push(0) - 1;
      const task = this.storage.upload(path, file, metadata);
      task.percentageChanges().subscribe(p=>{
        if (p) {
          this.uploads[uploadIndex] = p;
        }
      });
      task.then((snapshot)=>{
        // add image info to database
        this.store.collection<RefImage>("images").add(image);
      });


    });
  }

  identify(index: any, item: ImageRefs) {
    return item.doc.id;
  }

  identifyRequest(index: any, item: QueryDocumentSnapshot<ArtRequest>) {
    return item.id;
  }

  addRequest(form: NgForm) {
    if (!this.user.uid) return;
    if (form.pristine) return;
    let req: ArtRequest = {
      owner: this.user.uid,
      body: form.value.body,
      read: false,
      fav: false,
    };
    this.store.collection<ArtRequest>('requests').add(req).then(()=>{
      this.bodyForm = "";
    });
  }
}
