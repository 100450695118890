import { Component, OnInit } from '@angular/core';
import { AngularFirestore, QueryDocumentSnapshot } from '@angular/fire/firestore';
import { ArtRequest } from '../models/art-request.model';
import firebase from 'firebase/app';

@Component({
  selector: 'app-requests-viewer-page',
  templateUrl: './requests-viewer-page.component.html',
  styleUrls: ['./requests-viewer-page.component.scss']
})
export class RequestsViewerPageComponent implements OnInit {

  public readRequestList: firebase.firestore.QueryDocumentSnapshot<ArtRequest>[] = [];
  public unreadRequestList: firebase.firestore.QueryDocumentSnapshot<ArtRequest>[] = [];

  public readIsLoading: boolean = true;
  public unreadIsLoading: boolean = true;

  constructor(
    private storage: AngularFirestore
  ) { }

  ngOnInit(): void {
    this.storage.collection<ArtRequest>("requests").ref.where('read', '==', false).orderBy('fav', 'desc').onSnapshot(requests=>{
      this.unreadRequestList = requests.docs;
      this.unreadIsLoading = false;
    });
    this.storage.collection<ArtRequest>("requests").ref.where('read', '==', true).where('fav', '==', true).onSnapshot(requests=>{
      this.readRequestList = requests.docs;
      this.readIsLoading = false;
    });
  }

  identifyRequest(index: any, item: QueryDocumentSnapshot<ArtRequest>) {
    return item.id;
  }

}
