<button mat-stroked-button routerLink="/users"><mat-icon>person</mat-icon> Users</button>
<button mat-stroked-button routerLink="/requests"><mat-icon>pages</mat-icon> Requests</button>
<mat-spinner [diameter]="40" *ngIf="isLoadingUser"></mat-spinner>
<ng-container *ngIf="isLoaded">
  <p>Request page for</p>
  <mat-card-header>
    <img mat-card-avatar [src]="avatar" alt="icon">
    <mat-card-title>{{ displayName }}</mat-card-title>
    <mat-card-subtitle class="twitter-link" (click)="goToTwitter()">{{ handle }} - Twitter authenticated</mat-card-subtitle>
    <button color="warn" mat-button (click)="auth.logout()">
      <mat-icon>logout</mat-icon>
      Logout
    </button>
  </mat-card-header>
</ng-container>  
<app-request-manager [user]="currentUser" *ngIf="currentUser"></app-request-manager>