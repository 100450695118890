import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from "@angular/fire/firestore";
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { RequestUser } from './login-page/request-user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _userCredentials: Subject<firebase.auth.UserCredential> = new Subject<firebase.auth.UserCredential>();
  public get userCredentials() : Observable<firebase.auth.UserCredential> {
    return this._userCredentials.asObservable();
  }

  private _isLoggedIn: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(false);
  public get isLoggedIn() : Observable<Boolean> {
    return this._isLoggedIn.asObservable();
  }

  public newUser: RequestUser;

  public isAdmin: boolean = false;

  constructor (
    public afs: AngularFirestore, 
    public afAuth: AngularFireAuth,
    public ngZone: NgZone,
    public storage: AngularFirestore,
    public router: Router,
  ) {
    this.afAuth.user.subscribe(user=>{
      if (user?.uid === "DWecoc11C3dVTnVgpRTpjIcVM892") {
        this.isAdmin = true;
      }
    })
  }

  TwitterAuth() {
    return this.AuthLogin(new firebase.auth.TwitterAuthProvider());
  }

  AuthLogin(provider: firebase.auth.AuthProvider) {
    return this.afAuth.signInWithPopup(provider)
    .then((result) => {
      if (result) {
        this.updateUser(result);
        this._userCredentials.next(result);
        this._isLoggedIn.next(true);
        this.router.navigate(["/"]);
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  logout() {
    this.afAuth.signOut();
    this._isLoggedIn.next(false);
    this._userCredentials.next(undefined);
    this.router.navigate([""]);
  }

  updateUser(credential: firebase.auth.UserCredential|null) {
    if (!credential?.user) return;
    const requestUser = {
      uid: credential?.user?.uid,
      username: credential.user?.displayName,
      handle: credential.additionalUserInfo?.username,
      photo: credential.user?.photoURL,
    }
    this.storage.doc("users/"+credential?.user?.uid).set(requestUser).then(()=>{
      this.newUser = <RequestUser>requestUser;
    }
    );
  }

}
