<mat-card *ngIf="isLoggedIn">
    <mat-card-header>
      <mat-card-title><mat-icon>images</mat-icon> Reference Images</mat-card-title>
      <mat-card-subtitle>Any images you would like to share!</mat-card-subtitle>
    </mat-card-header>
    <div class="thumb-area">
      <app-image-thumb [imageRef]="image" *ngFor="let image of imageRefs; let i = index; trackBy:identify"></app-image-thumb>
      <ng-container *ngFor="let upload of uploads">
        <mat-card class="image-thumb-card upload" *ngIf="upload < 100">
          <mat-spinner [diameter]="70" mode="determinate" [value]="upload"></mat-spinner>
        </mat-card>
      </ng-container>
      <label mat-label for="ref-image-upload">
        <input  
        class="hidden-input"
        multiple 
        type="file"
        (change)="onInputChange($event)"
        name="ref-image-upload"
        accept=".png, .jpg, .jpeg, .tiff, .webp" 
        id="ref-image-upload"/>
        <mat-card class="image-thumb-card upload">
          <mat-icon>add</mat-icon>
        </mat-card>
      </label>
    </div>
    <app-global-details [uid]="user.uid"></app-global-details>
</mat-card>

<mat-card *ngIf="isLoggedIn">
  <form #form="ngForm">
    <mat-card-header>
      <mat-card-title><mat-icon>info</mat-icon> New request</mat-card-title>
      <mat-card-subtitle>
        <p>Here you can submit your actual artwork ideas! For ease of organizaing them on my end, please try to keep it to one idea per request. You can submit as many as you like, submitting more ideas means you're more likely to hit on something I'd really like to draw!</p>
        <p>Feel free to be as short or formal as you'd like. Though if the idea is too vague, I may not be comfortable extrapolating on what you want and accidentally drawing something you don't like.</p>
        <p>Keep in mind that you are infinitely more likely to get artwork if your art includes my own characters as well. Requests which portray my characters in ways that doesn't fit with their design or personality may be deleted. Don't let that stop you from trying though.</p>
        <p>No idea is too simple, too complex, too vanilla, too extreme, or off-limits. The worst that can happen is that I don't draw it.</p>
      </mat-card-subtitle>
    </mat-card-header>
    <mat-form-field class="request-form" appearance="fill">
      <mat-label>Request details</mat-label>
      <textarea name="body" cdkTextareaAutosize [(ngModel)]="bodyForm" matInput placeholder="Your request details"></textarea>
    </mat-form-field>
  </form>
  <mat-card-actions>
    <button (click)="addRequest(form)" [disabled]="form.pristine || form.value.body == ''" mat-stroked-button color="primary">Submit request</button>
  </mat-card-actions>
</mat-card>

<mat-card>
  <mat-card-title>Your requests</mat-card-title>
  <mat-card *ngIf="!requests || requests?.length === 0; else requestGrid">
    <p>You have no art requests. Use the above form to submit one!</p>
  </mat-card>
  <ng-template #requestGrid>
    <mat-card-content>
      <mat-card-subtitle>You have {{ requests?.length }} {{ requests?.length === 1 ? "request" : "requests"}}!</mat-card-subtitle>
      <app-request-card class="request-card" [request]="request" *ngFor="let request of requests; trackBy: identifyRequest"></app-request-card>
    </mat-card-content>
  </ng-template>
</mat-card>