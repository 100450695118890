import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsAdminGuard } from './IsAdminGuard';
import { IsLoggedInGuard } from './IsLoggedInGuard';
import { IsLoggedOutGuard } from './IsLoggedOutGuard';
import { LoginPageComponent } from './login-page/login-page.component';
import { RedirectGuard } from './RedirectGuard';
import { RequestsViewerPageComponent } from './requests-viewer-page/requests-viewer-page.component';
import { UserPageComponent } from './user-page/user-page.component';
import { UserViewComponent } from './user-view/user-view.component';

const routes: Routes = [
  { path: 'requests', component: RequestsViewerPageComponent, canActivate: [IsAdminGuard]},
  { path: 'users', component: UserViewComponent, canActivate: [IsAdminGuard]},
  { path: 'login', component: LoginPageComponent, canActivate: [IsLoggedOutGuard] },
  { path: 'user/:uid', component: UserPageComponent, canActivate: [IsLoggedInGuard] },
  { path: '**', component: LoginPageComponent, canActivate: [RedirectGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
