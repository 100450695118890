import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators"


@Injectable({
    providedIn: 'root'
  })
export class IsLoggedInGuard implements CanActivate {
  constructor(
      public afAuth: AngularFireAuth,
      private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
    return this.afAuth.user.pipe(
        map((x)=>{
            if (x) {
                return true;
            } else {
                return this.router.createUrlTree(["login"]);
            };
        })
    );
  }
}