<mat-card>
    <mat-card-header>
        <mat-card-title>
            <mat-icon>list</mat-icon>
            Ref details
        </mat-card-title>
        <mat-card-subtitle>
            <p>Any global details that apply to all requests or references can go here! Changes in character design, absolute do-nots, information you'd like for me to know, etc. This doesn't count as a request, the actual request(s) can be submitted below this.</p>
        </mat-card-subtitle>
    </mat-card-header>
    <ng-container *ngIf="!isLoading">
        <mat-form-field class="details-form" appearance="fill">
            <mat-label>Ref details</mat-label>
            <textarea 
            name="body" 
            class="fullwidth"
            cdkTextareaAutosize
            [(ngModel)]="body"
            matInput 
            rows="4"
            placeholder="Your reference details"
            (ngModelChange)="checkChanged()"></textarea>
        </mat-form-field>
        <mat-card-actions>
            <button *ngIf="!hasChanged" disabled mat-flat-button color="primary">Changes saved</button>
            <button *ngIf="hasChanged" (click)="updateDetails()" mat-flat-button color="primary">Save details</button>
        </mat-card-actions>
    </ng-container>
    <mat-spinner *ngIf="isLoading" diameter="40"></mat-spinner>
  </mat-card>