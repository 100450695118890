<mat-card>
    <mat-card-content>
        <form action="" #form="ngForm">
            <ng-container *ngIf="!isEditing">
                <p class="body-text">
                    {{ request.data().body }}
                </p>
            </ng-container>
            <mat-form-field class="request-form" appearance="fill" *ngIf="isEditing">
                <mat-label>Request details</mat-label>
                <textarea name="body" cdkTextareaAutosize [(ngModel)]="requestBody" matInput placeholder="Your request details">
                </textarea>
            </mat-form-field>
        </form>
    </mat-card-content>
    <mat-card-actions>
        <span>
            <button mat-stroked-button color="warn" (click)="stopEditing()" *ngIf="isEditing">
                <mat-icon>cancel</mat-icon>
                <mat-label class="hide-mobile"> Cancel</mat-label>
            </button>
            <button mat-stroked-button color="primary" (click)="updateRequest(form)" *ngIf="isEditing">
                <mat-icon>update</mat-icon>
                <mat-label class="hide-mobile"> Update</mat-label>
            </button>
            <button mat-button (click)="startEditing(form)" *ngIf="!isEditing && canEdit">
                <mat-icon>edit</mat-icon>
                <mat-label class="hide-mobile"> Edit</mat-label>
            </button>
            <button mat-button (click)="favRequest(true)" *ngIf="auth.isAdmin && !request.data().fav">
                <mat-icon>star</mat-icon>
                <mat-label class="hide-mobile"> Mark Fav</mat-label>
            </button>
            <button mat-button color="warn" (click)="favRequest(false)" *ngIf="auth.isAdmin && request.data().fav">
                <mat-icon>star</mat-icon>
                <mat-label class="hide-mobile"> Remove Fav</mat-label>
            </button>
            <button mat-button (click)="readRequest(true)" *ngIf="auth.isAdmin && !request.data().read">
                <mat-icon>book</mat-icon>
                <mat-label class="hide-mobile"> Mark read</mat-label>
            </button>
            <button mat-button color="warn" (click)="readRequest(false)" *ngIf="auth.isAdmin && request.data().read">
                <mat-icon>book</mat-icon>
                <mat-label class="hide-mobile"> Mark unread</mat-label>
            </button>
            <button mat-button *ngIf="showUserBtn" [routerLink]="['/user/', request.data().owner]">
                <mat-icon>person</mat-icon>
                <mat-label class="hide-mobile"> User</mat-label>
            </button>
        </span>
        <button mat-button color="warn" class="delete" (click)="deleteRequest()">
            <mat-icon>delete</mat-icon>
            <mat-label class="hide-mobile"> Delete</mat-label>
        </button>
    </mat-card-actions>
</mat-card>